=breakpoint($device)
  @if $device == "tablet"
    @media only screen and (max-width: 959px)
      @content
  @else if $device == "phone"
    @media only screen and (max-width: 639px)
      @content

=clearfix
  &::after
    display: block
    content: ""
    clear: both
