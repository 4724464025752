@import './../../styles/variables'
@import './../../styles/mixins'

.header
  box-sizing: border-box
  position: sticky
  top: -70px
  display: flex
  flex-direction: column
  width: 92%
  padding:
    right: 20px
    left: calc(8% + 20px)
  margin:
    right: auto
  z-index: 4
  background-color: $black
  
  &__nav
    display: flex
    justify-content: space-between
    align-items: center
    padding: 20px 0
  
  &__close
    display: none
    position: absolute
    right: 5px
    top: 5px
    font-size: 24px

  +breakpoint(phone)
    width: 100%
    display: none
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 5
    box-shadow: none
    border: none
    padding:
      right: 10px
      left: 10px
    
    &.is-visible
      display: flex
    
    &__close
      display: block

    &__nav, .nav-social
      flex-direction: column

.mobile-nav
  position: sticky
  top: 5px
  z-index: 3
  display: none
  padding: 8px 11px
  border: 1px solid $alarm
  margin: 5px
  font-size: 24px
  background-color: $black

  +breakpoint(phone)
    display: inline-block

.logo
  width: 200px
  display: block
  margin: 20px 0px 0 auto

  +breakpoint(phone)
    width: 100%
    max-width: 200px
    margin: 60px auto 20px
