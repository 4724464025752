@import 'styles/reset'
@import 'assets/css/all.css'
@import 'styles/variables'
@import 'styles/fonts'
@import 'styles/mixins'
@import 'styles/helper'

.main-body
  background-color: $background
  color: $white
  font-family: "Open Sans", Helvetica, Arial, sans-serif

.main-content
  position: relative
  width: 84%
  box-sizing: border-box
  word-wrap: break-word
  padding:
    right: 20px
    left: 20px
  margin:
    top: 20px
    right: auto
    left: auto
  +clearfix
  +breakpoint(tablet)
    width: 100%
  +breakpoint(phone)
    padding:
      right: 10px
      left: 10px

.main-nav
  font-size: 20px
  text-transform: uppercase
  text-align: center

  &__item
    display: inline-block
    &:not(:last-of-type)
      margin-right: 20px

  +breakpoint(phone)
    display: flex
    flex-direction: column
    align-items: center
    margin-bottom: 32px

    &__item
      margin-top: 16px

      &:not(:last-of-type)
        margin-right: 0

.nav-social
  list-style: none
  font-size: 24px
  text-align: center

  &__item
    display: inline-block
    &:not(:last-of-type)
      margin-right: 20px

.youtube-videos
  display: flex
  flex-direction: column
  align-items: center

.main-footer
  position: relative
  bottom: 0
  left: 0
  right: 0
  margin-top: 20px
  padding: 10px
  text-align: center

  a
    margin-right: 8px
    margin-left: 8px

.impressum-button
  position: fixed
  top: 8px
  left: 8px
  border: 2px solid $alarm
  color: $white
  padding: 8px 16px
  background-color: transparent

  .fa-solid
    margin-right: 8px

.giphy
  margin-top: 32px
  display: flex
  justify-content: center
