$white: #fff
$black: #072B27
$alarm: #FF7AE3
$alarm-dark: #8A0077
$accent: #00AE99
$accent-light: #C2FCF3
$warning: #CDD821

// old layout
$background: $black
$highlight: $accent
$highlight-light: $accent-light
$font-color: $white


