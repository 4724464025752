@import './../../styles/_variables'

.video
  position: relative

  &__icon
    position: absolute
    width: 40px
    height: 40px
    padding: 5px
    border: 2px solid $white
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    z-index: 3
    top: 50%
    margin-top: -20px
    left: 50%
    margin-left: -20px
    color: $white
    font-size: 32px

    .fa-play
      margin-left: 5px

    &.is-playing
      display: none
