@import '../../styles/_variables'

.link-page
  padding: 0 20px
  height: 100vh
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  h1
    text-align: center
    margin-bottom: 20px

.header-image
  width: 150px
  height: 150px
  margin-bottom: 20px
  background-image: url('../../assets/images/epk/img_1.jpg')
  background-size: cover
  background-position-x: 81%
  border-radius: 50%
  border: 4px solid $accent-light

.button-links
  display: flex
  flex-direction: column
  margin-bottom: 20px

  &__item
    padding: 7px 64px
    margin-bottom: 8px
    border: 2px solid $accent
    text-align: center

    &:hover
      cursor: pointer
      border-color: $alarm
