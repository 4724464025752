.is-visible-for-mobile, .is-visible-for-phone
  display: none
  +breakpoint(phone)
    display: inherit

.is-visible-for-mobile
  +breakpoint(tablet)
    display: inherit

.is-hidden-for-mobile
  display: inherit
  +breakpoint(tablet)
    display: none

.is-hidden-for-mobile
  display: inherit
  +breakpoint(phone)
    display: none

.spacing-bottom-16
  margin-bottom: 16px

.spacing-bottom-24
  margin-bottom: 24px
