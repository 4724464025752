/* ------------------------------------------
 *  RESET
 *---------------------------------------------

body, div, h1, h2, h3, h4, h5, h6, p, blockquote, pre, dl, dt, dd, ol, ul, li, hr, fieldset, form, label, legend, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, summary, hgroup
  margin: 0
  padding: 0
  border: 0

h1, h2, h3, h4, h5, h6, small
  font-size: 1em

@-webkit-viewport
  width: device-width

@-moz-viewport
  width: device-width

@-ms-viewport
  width: device-width

@-o-viewport
  width: device-width

@viewport
  width: device-width

img, video
  max-width: 100%
  height: auto

html
  -webkit-text-size-adjust: 100%
  -ms-text-size-adjust: 100%
  text-size-adjust: 100%

/* ------------------------------------------
 *  CONSISTENCY FIXES
 *---------------------------------------------

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary
  display: block

audio, canvas, video
  display: inline-block

audio:not([controls])
  display: none
  height: 0

[hidden]
  display: none

html
  -webkit-text-size-adjust: 100%
  -ms-text-size-adjust: 100%
  text-size-adjust: 100%

a
  &:active, &:hover
    outline: 0

abbr[title]
  border-bottom: 1px dotted

b, strong
  font-weight: bold

dfn
  font-style: italic

hr
  color: transparent
  background: none
  -moz-box-sizing: content-box
  box-sizing: content-box
  height: 0

mark
  background: #ff0
  color: #000

code, kbd, pre, samp
  font-family: monospace, monospace
  font-size: 1em

pre
  white-space: pre-wrap

q
  quotes: "\201C" "\201D" "\2018" "\2019"

small
  font-size: 80%

sub
  font-size: 75%
  line-height: 0
  position: relative
  vertical-align: baseline

sup
  font-size: 75%
  line-height: 0
  position: relative
  vertical-align: baseline
  top: -0.5em

sub
  bottom: -0.25em

svg:not(:root)
  overflow: hidden

ul
  list-style-type: none

ol
  list-style-type: decimal

nav
  ol, ul
    list-style: none
    margin: 0
    padding: 0

button, input, select, textarea
  font-size: 100%
  margin: 0

button, input
  line-height: normal

button, select
  text-transform: none

button, html input[type="button"]
  -webkit-appearance: button
  cursor: pointer

input
  &[type="reset"], &[type="submit"]
    -webkit-appearance: button
    cursor: pointer

button[disabled], html input[disabled]
  cursor: default

input
  &[type="checkbox"], &[type="radio"]
    box-sizing: border-box
    padding: 0

  &[type="search"]
    -webkit-appearance: textfield
    -moz-box-sizing: content-box
    -webkit-box-sizing: content-box
    box-sizing: content-box

    &::-webkit-search-cancel-button, &::-webkit-search-decoration
      -webkit-appearance: none

button::-moz-focus-inner, input::-moz-focus-inner
  border: 0
  padding: 0

textarea
  overflow: auto
  vertical-align: top

table
  border-collapse: collapse
  border-spacing: 0

a img, img
  -ms-interpolation-mode: bicubic
  border: 0
