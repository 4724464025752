@import '../../styles/variables'

.main-page
  .h1
    position: relative
    z-index: 2

    &::after
      position: absolute
      left: -128px
      content: ''
      width: 20%
      height: 2px
      display: block
      background-color: $alarm
      z-index: 0

.about-view
  display: flex

  img
    margin-left: 20px
    margin-bottom: 20px
