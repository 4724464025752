@import './../../styles/variables'
@import './../../styles/mixins'

.moving-banner
  display: flex
  align-content: center
  justify-content: center

  &__text
    text-shadow: 3px 3px 0 $warning, 6px 6px 0 $alarm-dark, 9px 9px $accent, 12px 12px 0 $alarm
    font-weight: 800
    text-transform: uppercase
    font-size: 4vw
    text-align: center
    margin: 0
    color: $alarm
    animation: shadows 2s ease-in infinite, move 2s ease-in infinite
    letter-spacing: 0.4rem
  
  +breakpoint(phone)
    &__text
      font-size: 32px


@keyframes shadows 
  0% 
    text-shadow: none
  
  10% 
    text-shadow: 3px 3px 0 $warning
  
  20% 
    text-shadow: 3px 3px 0 $warning, 6px 6px 0 $alarm-dark
  
  30% 
    text-shadow: 3px 3px 0 $warning, 6px 6px 0 $alarm-dark, 9px 9px $accent
  
  40% 
    text-shadow: 3px 3px 0 $warning, 6px 6px 0 $alarm-dark, 9px 9px $accent, 12px 12px 0 $alarm
  
  50% 
    text-shadow: 3px 3px 0 $warning, 6px 6px 0 $alarm-dark, 9px 9px $accent, 12px 12px 0 $alarm
  
  60% 
    text-shadow: 3px 3px 0 $warning, 6px 6px 0 $alarm-dark, 9px 9px $accent, 12px 12px 0 $alarm
  
  70% 
    text-shadow: 3px 3px 0 $warning, 6px 6px 0 $alarm-dark, 9px 9px $accent
  
  80% 
    text-shadow: 3px 3px 0 $warning, 6px 6px 0 $alarm-dark
  
  90% 
    text-shadow: 3px 3px 0 $warning
  
  100% 
    text-shadow: none
  


@keyframes move 
  0% 
    transform: translate(0px, 0px)
  
  40% 
    transform: translate(-12px, -12px)
  
  50% 
    transform: translate(-12px, -12px)
  
  60% 
    transform: translate(-12px, -12px)
  
  100% 
    transform: translate(0px, 0px)
  
