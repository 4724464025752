
  /* latin */
@font-face
  font-family: 'Raleway'
  font-style: normal
  font-weight: 400
  src: url('../assets/fonts/Raleway-Regular.ttf') format('truetype')
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
/* latin */
@font-face
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: 400
  src: url('../assets/fonts/RobotoCondensed-Regular.ttf') format('truetype')
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD

=roboto
  font-family: 'Roboto Condensed', sans-serif
=raleway
  font-family: 'Raleway', sans-serif

.main-h1
  padding-top: 64px
  padding-bottom: 32px
  +raleway
  font-size: 32px
  line-height: 1.5

.h1, .h2
  padding-top: 32px
  padding-bottom: 24px
  font-weight: 900
  color: $accent-light
  +raleway
  --x-offset: -0.0625em
  --y-offset: 0.0625em
  --stroke: 0.025em
  text-shadow: var(--x-offset) var(--y-offset) 0px $black, calc( var(--x-offset) - var(--stroke) ) calc( var(--y-offset) + var(--stroke) ) 0px $alarm

.h1
  font-size: 48px

.h2
  font-size: 32px

.main-h2
  +raleway
  color: $alarm
  font-size: 20px

.main-h3
  padding-top: 20px
  font-size: 18px

.main-text
  +raleway
  padding-top: 10px
  font-size: 16px
  line-height: 1.6
  + .main-h2
    padding-top: 20px

.main-fineprint
  +raleway
  font-size: 12px
  line-height: 1.6
  color: $font-color

.main-link, .inline-link
  +raleway
  &:visited, &:focus, &:active
    color: $font-color
  &:hover, &.is-current
    color: $highlight-light

.main-link
  position: relative
  color: $font-color
  text-decoration: none

  &::after, &::before
    transition: width .3s ease-out
    content: ''
    width: 0%
    height: 1px
    position: absolute
    bottom: -4px
    background-color: $alarm

  &::after
    left: 50%

  &::before
    right: 50%
  
  &:hover, &.is-current
    &::after, &::before
      transition: width .3s ease-in
      width: 50%

.inline-link
  color: $highlight-light

  &:visited, &:focus, &:active
    color: $highlight-light

  &:hover
    color: $alarm

.promo-text
  +raleway
  font-size: 20px
  text-align: center
  line-height: 1.6
