@import '../../styles/mixins'
@import '../../styles/variables'

.epk-page
  padding-bottom: 64px

  .header
    border-bottom: 1px solid $alarm
    border-right: 1px solid $alarm
    box-shadow: rgba($alarm, 0.4) 5px 5px, rgba($alarm, 0.3) 10px 10px, rgba($alarm, 0.2) 15px 15px, rgba($alarm, 0.1) 20px 20px, rgba($alarm, 0.05) 25px 25px

  .main-content
    margin-top: 80px

    +breakpoint(phone)
      margin-top: 20px
  .h1
    text-align: center

  .h1, .h2
    padding-top: 64px
    padding-bottom: 48px

  +breakpoint(phone)
    .h2
      padding-top: 20px
      padding-bottom: 16px

  .nav-social
    display: inline-block
    float: right

    &__item
      &:not(:last-of-type)
        margin-right: 10px

  .main-nav
    text-align: left
    margin:
      top: 0
    +breakpoint(tablet)
      width: 70%
    +breakpoint(phone)
      padding:
        right: 10px
        left: 10px

.epk-split-content
  display: inline-block
  width: 60%
  padding-right: 20px
  text-align: justify
  .main-text:first-of-type
    padding-top: 0
  +breakpoint(tablet)
    width: 48%
  +breakpoint(phone)
    width: 100%
    padding-right: 0

.epk-split-sidebar
  display: inline-block
  width: 35%
  vertical-align: top
  text-align: right
  +breakpoint(tablet)
    width: 47%
  +breakpoint(phone)
    width: 100%
    margin-top: 20px
    text-align: center

.epk-foto
  display: inline-block
  width: 49%
  +breakpoint(phone)
    width: 100%

.epk-links
  padding-top: 20px

  &__item
    display: block
    padding-top: 10px

.epk-press-split
  display: inline-flex
  justify-content: space-between

  &__item
    width: 48%

    i
      margin-right: 8px

  +breakpoint(phone)
    flex-direction: column
    &__item
      width: 100%

      &:first-of-type
        margin-bottom: 16px

.epk-press-preview
  margin-top: 20px
  border: 3px solid $highlight
  +breakpoint(tablet)
    display: block
    margin:
      left: auto
      right: auto
  +breakpoint(phone)
    width: 100%

.epk-download-preview
  display: inline-block
  margin-top: 20px
  padding: 15px 30px
  border: 1px solid $white
  border-radius: 4px
  &:hover, &.is-current
    border-color: $highlight

.epk-inline-list
  list-style: none
  &__item
    display: inline-block
    width: 20%
    margin-right: 10px
    text-align: center
  +breakpoint(tablet)
    &__item
      width: 48%
      &:nth-child(3), &:nth-child(4)
        margin-top: 20px
  +breakpoint(phone)
    &__item
      width: 100%
      margin-right: 0
      &:not(:first-of-type)
        margin-top: 20px

.epk-videos
  display: flex
  justify-content: space-between

  video
    width: 20vw

  &__center
    display: flex
    flex-direction: column
    justify-content: space-between

    video
      width: 30vw
  
  +breakpoint(phone)
    flex-direction: column
    align-items: center

    video
      width: 100%

.epk-contact
  .main-text
    display: flex
    flex-direction: column

.epk-youtube-videos
  display: flex
  flex-wrap: wrap
  justify-content: space-evenly

  &__item
    margin-bottom: 32px

  +breakpoint(phone)
    &__item
      width: 100%

.epk-start
  display: flex
  justify-content: space-between

  img
    margin-right: 138px
    box-shadow: rgba($alarm, 0.4) 5px 5px, rgba($alarm, 0.3) 10px 10px, rgba($alarm, 0.2) 15px 15px, rgba($alarm, 0.1) 20px 20px, rgba($alarm, 0.05) 25px 25px
  
  .moving-banner
    position: relative

    &__text
      position: absolute
      top: 0
      left: 50px

  +breakpoint(phone)
    flex-direction: column

    .moving-banner__text
      position: relative
      top: inherit
      left: inherit
      rotate: none

    img
      width: 100%
      margin-right: 0
